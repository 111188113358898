import { Stack, ThemeProvider } from '@mui/material';
import {
  useAppVersion,
  useCurrentLanguage,
  useTitleEffect,
} from 'hive-react-utils';
import { useEffect } from 'react';
import { clinicianTheme } from './theme';
import Main from './content/Main';
import { DataContextProvider } from './contexts/DataContext';
import {
  CommonDataContextProvider,
  MessageBarProvider,
} from 'ecarepd-shared-utilities';
import { ConfirmProvider } from 'material-ui-confirm';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/fr';
import moment from 'moment';

export default function App() {
  useTitleEffect('app.title');
  const language = useCurrentLanguage();

  useEffect(() => {
    moment.locale(language);
  }, [language]);

  const { version } = useAppVersion();
  useEffect(() => console.debug(`App Version: ${version}`), [version]);

  return (
    <ThemeProvider theme={clinicianTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MessageBarProvider>
          <CommonDataContextProvider>
            <DataContextProvider>
              <ConfirmProvider>
                <Stack height="100%">
                  <Main />
                </Stack>
              </ConfirmProvider>
            </DataContextProvider>
          </CommonDataContextProvider>
        </MessageBarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
