import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DataContext } from '../../contexts/DataContext';
import { PATH_CALL_REQUEST, PATH_CALL_REQUESTS } from '../constants';
import { Navigation } from '../../components/Navigation';

export function CallRequestNavigation(): JSX.Element {
  const { t } = useTranslation();
  const { callRequests } = useContext(DataContext);
  const { callRequestId } = useParams();

  return (
    <Navigation
      name="CALL_REQUESTS"
      pathAll={PATH_CALL_REQUESTS}
      path={PATH_CALL_REQUEST}
      pathParam=":callRequestId"
      items={callRequests}
      itemId={callRequestId || ''}
      labels={{
        previous: t('content.call_request.navigation.previous'),
        next: t('content.call_request.navigation.next'),
        all: t('content.call_request.navigation.alls'),
      }}
    />
  );
}
