import {
  Button,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  Clinician,
  DialogTitleWithClose,
  PatientService,
  MessageBarContextProps,
} from 'ecarepd-shared-utilities';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageBarContext } from 'ecarepd-shared-utilities';
import { validateEmail } from '../../services/ValidationService';
import { DataContext } from '../../contexts/DataContext';
import _ from 'lodash';
import { AnalyticsService, LoggedDialog } from 'hive-analytics-react';

export interface InvitePatientDialogProperties {
  open: boolean;
  onClose: () => void;
}

function InputField({
  name,
  autoComplete,
  value,
  label,
  setValue,
}: {
  name: string;
  autoComplete: string;
  value: string;
  label: string;
  setValue: (v: string) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
    [setValue]
  );
  return (
    <TextField
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      name={name}
      placeholder={t(label) || undefined}
      label={t(label)}
      fullWidth
    />
  );
}

export function InvitePatientDialog({
  open,
  onClose,
}: InvitePatientDialogProperties): JSX.Element {
  const { t } = useTranslation();
  const { clinicians } = useContext(DataContext);

  const [inviting, setInviting] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [clinicianId, setClinicianId] = useState('');
  useEffect(() => {
    setInviting(false);
    setFirstName('');
    setLastName('');
    setEmail('');
    setClinicianId('');
  }, [open]);

  const onClinicianChange = useCallback((event: SelectChangeEvent<string>) => {
    setClinicianId(event.target.value);
  }, []);

  const { openMessageBar, closeMessageBar } =
    useContext<MessageBarContextProps>(MessageBarContext);

  const sendInvitation = useCallback(
    (e: any) => {
      AnalyticsService.logClick('INVITE_PATIENT');
      e.preventDefault();
      setInviting(true);
      PatientService.invitePatient(firstName, lastName, email, clinicianId)
        .then(() => {
          setInviting(false);

          openMessageBar(
            true,
            t('common.success'),
            {
              autoHideDuration: 2000,
            },
            {
              severity: 'success',
            }
          );

          onClose();
        })
        .catch((error: any) => {
          console.error(error);
          openMessageBar(
            true,
            t('content.patients_table.invite_dialog.error'),
            {
              onClose: () => {
                setInviting(false);
                closeMessageBar();
              },
            },
            {
              severity: 'error',
            }
          );
        });
    },
    [
      firstName,
      lastName,
      email,
      clinicianId,
      openMessageBar,
      t,
      onClose,
      closeMessageBar,
    ]
  );

  const sendDisabled =
    inviting || !firstName || !clinicianId || !validateEmail(email);

  return (
    <LoggedDialog
      dialogName="invite-patient"
      open={open}
      onClose={inviting ? undefined : onClose}
      fullWidth
    >
      <DialogTitleWithClose onClose={onClose} disabled={inviting}>
        {t('content.patients_table.invite_dialog.title')}
      </DialogTitleWithClose>

      <DialogContent dividers>
        <form onSubmit={sendInvitation}>
          <Stack spacing={6}>
            <Stack spacing={6} direction="row">
              <InputField
                name="firstname"
                autoComplete="given-name"
                label="content.patients_table.invite_dialog.first_name"
                value={firstName}
                setValue={setFirstName}
              />
              <InputField
                name="lastname"
                autoComplete="family-name"
                label="content.patients_table.invite_dialog.last_name"
                value={lastName}
                setValue={setLastName}
              />
            </Stack>

            <InputField
              name="email"
              autoComplete="email"
              label="content.patients_table.invite_dialog.email"
              value={email}
              setValue={setEmail}
            />

            <FormControl fullWidth>
              <InputLabel id="clinician-select-label">
                {t('content.patients_table.assigned_to')}
              </InputLabel>
              <Select
                value={clinicianId || ''}
                onChange={onClinicianChange}
                label={t('content.patients_table.assigned_to')}
              >
                {_.map(clinicians, (c: Clinician) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </form>
      </DialogContent>

      <DialogActions>
        <Button disabled={inviting} onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button disabled={sendDisabled} onClick={sendInvitation}>
          {t('content.patients_table.invite_dialog.send')}
        </Button>
      </DialogActions>
    </LoggedDialog>
  );
}
