import { Button, Stack, Typography, useTheme } from '@mui/material';
import MaterialTable, {
  Column,
  Localization,
  Options,
} from '@material-table/core';
import { COLOURS, Patient, PatientStatus } from 'ecarepd-shared-utilities';
import { useTranslation } from 'react-i18next';
import { InvitePatientDialog } from './InvitePatientDialog';
import {
  PropsWithoutRef,
  PropsWithRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_PATIENT } from '../constants';
import { DataContext } from '../../contexts/DataContext';

import moment from 'moment';
import _ from 'lodash';
import { RecentStatuses } from '../../components/RecentStatuses';

const MAX_DAYS_FOR_INACTIVE = 10;

interface InactiveStatusProps {
  days: number;
}

function InactiveStatus({
  days,
}: PropsWithoutRef<InactiveStatusProps>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" className="inactive">
      {t('content.patients_table.recent_status_invalid', { days })}
    </Typography>
  );
}

function PendingStatus(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" className="pending-status">
      {t('content.patients_table.recent_status_pending')}
    </Typography>
  );
}

interface ToolbarActionsProps {
  onInvitePatients: () => void;
}

function ToolbarActions({
  onInvitePatients,
}: PropsWithRef<ToolbarActionsProps>) {
  const { t } = useTranslation();
  return (
    <Stack marginLeft={4}>
      <Button variant="outlined" onClick={onInvitePatients}>
        {t('content.patients_table.invite_patients')}
      </Button>
    </Stack>
  );
}

export default function Patients() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { patients, hasAdministratorRole } = useContext(DataContext);

  const title = t('content.patients_table.title', { count: _.size(patients) });

  const renderPatientName = useCallback(
    (p: Patient) => {
      return (
        <Typography
          variant="body2"
          className={
            hasAdministratorRole &&
            p.properties.status === PatientStatus.Pending
              ? 'pending'
              : undefined
          }
        >
          {p.properties.name}
        </Typography>
      );
    },
    [hasAdministratorRole]
  );

  const renderClinicianName = useCallback(
    (p: Patient) => {
      const clinician = p.properties.clinician;
      return (
        <Typography
          variant="body1"
          className={
            p.properties.status === PatientStatus.Pending
              ? 'pending'
              : undefined
          }
        >
          {clinician?.name || clinician?.email || t('not_available')}
        </Typography>
      );
    },
    [t]
  );

  const renderRecentStatus = useCallback(
    (p: Patient) => {
      if (
        hasAdministratorRole &&
        p.properties.status === PatientStatus.Pending
      ) {
        return <PendingStatus />;
      }

      const m = moment(p.properties.lastReportDate);
      if (m.isValid() && m.diff(moment(), 'days') <= MAX_DAYS_FOR_INACTIVE) {
        return <RecentStatuses recentStatus={p.properties.recentStatus} />;
      } else {
        return <InactiveStatus days={MAX_DAYS_FOR_INACTIVE} />;
      }
    },
    [hasAdministratorRole]
  );

  const renderLastReportDate = useCallback(
    (p: Patient) => {
      if (!p?.properties?.lastReportDate) {
        return t('content.patients_table.last_report_date_unavailable');
      }

      return (
        <Typography
          variant="body1"
          className={
            p.properties.status === PatientStatus.Pending
              ? 'pending'
              : undefined
          }
        >
          {moment(p.properties.lastReportDate).format('LL')}
        </Typography>
      );
    },
    [t]
  );

  // Note: using a state to prevent warning about render function not stable.
  // This does not change while user is logged in.
  const [columns] = useState<Array<Column<Patient>>>(() => {
    if (hasAdministratorRole) {
      return [
        {
          title: t('content.patients_table.patient_name'),
          width: '20%',
          field: 'properties.name',
          render: renderPatientName,
        },
        {
          title: t('content.patients_table.assigned_to'),
          width: '20%',
          field: 'properties.clinicianID',
          render: renderClinicianName,
          sorting: true,
        },
        {
          title: t('content.patients_table.last_report_date'),
          width: '60%',
          field: 'properties.lastReportDate',
          render: renderLastReportDate,
        },
      ];
    } else {
      return [
        {
          title: t('content.patients_table.patient_name'),
          width: '20%',
          field: 'properties.name',
          render: renderPatientName,
        },
        {
          title: t('content.patients_table.recent_status'),
          width: '60%',
          field: 'properties.recentStatus',
          render: renderRecentStatus,
          sorting: false,
        },
        {
          title: t('content.patients_table.last_report_date'),
          width: '20%',
          field: 'properties.lastReportDate',
          render: renderLastReportDate,
        },
      ];
    }
  });

  const options: Options<Patient> = useMemo(
    () => ({
      paging: false,
      sorting: true,
      rowStyle: (p: Patient) => {
        return hasAdministratorRole &&
          p.properties.status === PatientStatus.Pending
          ? {
              backgroundColor: theme.palette.primary.main,
              color: COLOURS.White,
            }
          : {};
      },
    }),
    [hasAdministratorRole, theme.palette.primary.main]
  );

  const localization: Localization = {
    toolbar: {
      searchPlaceholder: t('content.patients_table.search_placeholder'),
    },
  };

  const [showInvitePatientDialog, setShowInvitePatientDialog] = useState(false);

  const onRowClick = useCallback(
    (event: any, p?: Patient) => {
      if (hasAdministratorRole || !p || !p.id) {
        return;
      }

      navigate(PATH_PATIENT.replace(':patientId', p.id));
    },
    [hasAdministratorRole, navigate]
  );

  return (
    <div className="patients-table">
      <InvitePatientDialog
        open={showInvitePatientDialog}
        onClose={() => setShowInvitePatientDialog(false)}
      />

      <MaterialTable
        components={{
          Actions: (props: any) => {
            if (!hasAdministratorRole) {
              return null;
            }
            return (
              <ToolbarActions
                {...props}
                onInvitePatients={setShowInvitePatientDialog}
              />
            );
          },
        }}
        onRowClick={onRowClick}
        isLoading={!patients}
        title={title}
        columns={columns}
        data={patients || []}
        options={options}
        localization={localization}
      />
    </div>
  );
}
