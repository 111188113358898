import { useInstanceById } from 'hive-react-utils';
import { useEffect, useState } from 'react';
import _ from 'lodash';
function generateName(patient) {
    return _.join(_.compact([
        _.get(patient, 'properties.lastname'),
        _.get(patient, 'properties.firstname'),
    ]), ', ');
}
export function usePatientById(patientId) {
    const rawPatient = useInstanceById('ecarepd', 'patient', patientId);
    // Process the raw patient by adding the name from the first and last name values
    const [patient, setPatient] = useState(rawPatient);
    useEffect(() => {
        if (!rawPatient) {
            return;
        }
        setPatient(_.merge(rawPatient, {
            properties: {
                name: generateName(rawPatient),
            },
        }));
    }, [rawPatient]);
    return patient;
}
