import { Stack } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import _ from 'lodash';
import moment from 'moment';
import { PropsWithRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckboxGroup,
  CheckboxGroupEntry,
} from '../../../components/CheckboxGroup';
import { useSelectedValues } from './utilities';

export interface PeriodRangeProps {
  startTime: moment.Moment;
  setStartTime: (value: moment.Moment) => void;

  endTime: moment.Moment;
  setEndTime: (value: moment.Moment) => void;
}

const enum Periods {
  Last24Hours = '24',
  Last7Days = '168',
  Last30Days = '720',
  DateRange = 'Date Range',
}

export function PeriodRange({
  startTime: startTimeExt,
  setStartTime: setStartTimeExt,
  endTime: entTimeExt,
  setEndTime: setEndTimeExt,
}: PropsWithRef<PeriodRangeProps>): JSX.Element {
  const { t } = useTranslation();
  const PERIODS = useMemo<CheckboxGroupEntry[]>(
    () => [
      {
        id: Periods.Last24Hours,
        label: t('content.metrics.filters.period_range.last_24_hours'),
      },
      {
        id: Periods.Last7Days,
        label: t('content.metrics.filters.period_range.last_7_days'),
      },
      {
        id: Periods.Last30Days,
        label: t('content.metrics.filters.period_range.last_30_days'),
      },
      {
        id: Periods.DateRange,
        label: t('content.metrics.filters.period_range.date_range'),
      },
    ],
    [t]
  );

  const [selectedValues, setSelectedValues] = useSelectedValues([
    Periods.Last24Hours,
  ]);

  const [startTime, setStartTime] = useState(startTimeExt);
  const [endTime, setEndTime] = useState(entTimeExt);

  const onStartTimeChanged = useCallback(
    (value: moment.Moment | null) => {
      setStartTime(value || moment().startOf('day'));
    },
    [setStartTime]
  );

  const onEndTimeChanged = useCallback(
    (value: moment.Moment | null) => {
      setEndTime(value || moment().endOf('day'));
    },
    [setEndTime]
  );

  useEffect(() => {
    let start;
    let end;

    const period = _.get(
      Array.from(selectedValues),
      0,
      Periods.Last24Hours
    ) as Periods;

    switch (period) {
      case Periods.Last24Hours:
        start = moment().subtract(24, 'hours');
        end = moment();
        break;

      case Periods.Last7Days:
        start = moment().subtract(7, 'days');
        end = moment();
        break;

      case Periods.Last30Days:
        start = moment().subtract(30, 'days');
        end = moment();
        break;

      case Periods.DateRange:
        start = startTime;
        end = endTime;
        break;
    }

    setStartTimeExt(start);
    setEndTimeExt(end);
  }, [startTime, endTime, selectedValues, setStartTimeExt, setEndTimeExt]);

  return (
    <Stack spacing={4}>
      <CheckboxGroup
        exclusive
        titleId="content.metrics.filters.period_range.title"
        values={PERIODS}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
      >
        <Stack
          paddingTop={1}
          spacing={1}
          className="metrics-filters-date-range"
        >
          <DateTimePicker
            disabled={!selectedValues.has(Periods.DateRange)}
            value={startTime}
            onChange={onStartTimeChanged}
            // Not needed with @mui/x-date-pickers@6
            // renderInput={(params: any) => <TextField {...params} />}
          />

          <DateTimePicker
            disabled={!selectedValues.has(Periods.DateRange)}
            value={endTime}
            minDateTime={startTime}
            disableFuture
            onChange={onEndTimeChanged}
            // Not needed with @mui/x-date-pickers@6
            // renderInput={(params: any) => <TextField {...params} />}
          />
        </Stack>
      </CheckboxGroup>
    </Stack>
  );
}
