import { ThemeOptions } from '@mui/material';
import {
  MuiAccordion,
  MuiAccordionSummary,
  MuiAccordionDetails,
} from './accordion';
import { MuiCheckbox } from './checkbox';
import { MuiFormControl, MuiFormControlLabel } from './formControl';
import { MuiTableCell, MuiTableRow } from './table';
import { MuiTypography } from './typography';

export const metricsTheme: ThemeOptions = {
  components: {
    MuiAccordion,
    MuiAccordionSummary,
    MuiAccordionDetails,
    MuiCheckbox,
    MuiFormControl,
    MuiFormControlLabel,
    MuiTableCell,
    MuiTableRow,
    MuiTypography,
  },
};
