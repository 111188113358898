import { PropsWithChildren, useContext, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Stack } from '@mui/material';

import { useConnectionState } from 'hive-react-utils';
import { ConnectionService } from 'hive-client-utils';
import {
  Footer,
  FooterOptions,
  Authenticating,
  Connecting,
  Disconnected,
  Disconnecting,
  NotImplemented,
} from 'ecarepd-shared-utilities';

import htmlReactParser from 'html-react-parser';
import Header from '../components/Header';
import Content from './Content';
import { DataContext } from '../contexts/DataContext';
import { LocationTracker, LoggedConnectionService } from 'hive-analytics-react';

function InternalMain(): JSX.Element {
  const connectionState = useConnectionState();
  const { hasClinicianRole, hasAdministratorRole } = useContext(DataContext);

  return useMemo<JSX.Element>(() => {
    switch (connectionState) {
      case ConnectionService.ConnectionState.DISCONNECTED:
        return <Disconnected />;

      case ConnectionService.ConnectionState.DISCONNECTING:
        return <Disconnecting />;

      case ConnectionService.ConnectionState.CONNECTING:
      case ConnectionService.ConnectionState.CONNECTING_FROM_TOKEN:
        return <Connecting />;

      case ConnectionService.ConnectionState.CONNECTED:
      case ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN:
        if (hasClinicianRole == null || hasAdministratorRole == null) {
          return <Connecting />;
        } else if (!hasClinicianRole && !hasAdministratorRole) {
          console.warn('User does not have proper roles');
          LoggedConnectionService.releaseBee('Invalid roles');
          return <Connecting />;
        }
        return <Content />;

      default:
        return <NotImplemented />;
    }
  }, [connectionState, hasAdministratorRole, hasClinicianRole]);
}

function RouteElement({ children }: PropsWithChildren<any>): JSX.Element {
  const footerOptions: FooterOptions = {
    formatHtml: htmlReactParser,
  };
  return (
    <Stack height="100%">
      <Header />
      <Stack flexGrow="1">{children}</Stack>
      <Footer options={footerOptions} />
    </Stack>
  );
}

export default function Main(): JSX.Element {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <LocationTracker />
      <Routes>
        <Route
          path="/oidc"
          element={<RouteElement children={<Authenticating />} />}
        />

        <Route
          path="/*"
          element={<RouteElement children={<InternalMain />} />}
        />

        <Route
          path="*"
          element={<RouteElement children={<Navigate to="/" />} />}
        />
      </Routes>
    </Router>
  );
}
