import { ComponentsOverrides, Theme } from '@mui/material';
import { theme as baseTheme } from 'ecarepd-shared-utilities';

const titleStyleOverrides: ComponentsOverrides<Theme>['MuiDialogTitle'] = {
  root: {
    backgroundColor: baseTheme.palette.primary.dark,
  },
};

export const MuiDialogTitle = {
  styleOverrides: titleStyleOverrides,
};
