import { ComponentsOverrides } from '@mui/material';
import { COLOURS } from 'ecarepd-shared-utilities';

const headStyleOverrides: ComponentsOverrides['MuiTableHead'] = {
  root: {
    // When inside the patients-table div
    '.patients-table &, .call-requests-table &, .surveys-table &': {
      backgroundColor: COLOURS.LightBlueBackground,
    },
    '.symptoms-and-notes &': {
      // Nothing for now
    },
  },
};

export const MuiTableHead = {
  styleOverrides: headStyleOverrides,
};

const rowStyleOverrides: ComponentsOverrides['MuiTableRow'] = {
  root: {
    '.patients-table &, .call-requests-table &, .symptoms-and-notes &, .surveys-table &':
      {
        // Nothing for now

        '&:nth-of-type(even)': {
          backgroundColor: COLOURS.OddRowBackground,
        },

        // For the scale images (Sad, Happy, etc)
        '& .status-scale': {
          display: 'contents',
        },
        '& .status-scale svg': {
          // Figma says 18px, but it's too small, eyeballed to 24px
          width: '24px',
          height: 'auto',
        },
      },

    '.patients-table tbody &:last-child .MuiTableCell-root, .call-requests-table tbody &:last-child .MuiTableCell-root, .surveys-table tbody &:last-child .MuiTableCell-root':
      {
        borderBottom: 'unset',
      },
  },
};

export const MuiTableRow = {
  styleOverrides: rowStyleOverrides,
};

const cellStyleOverrides: ComponentsOverrides['MuiTableCell'] = {
  root: {
    '.patients-table &, .call-requests-table &, .symptoms-and-notes &, .surveys-table &':
      {
        backgroundColor: 'unset',
        paddingTop: 'unset',
        paddingBottom: 'unset',

        '& .MuiTypography-body1': {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '13px',
          lineHeight: '30px',
          color: COLOURS.DarkGrey,
        },

        '& .MuiTypography-body2': {
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '30px',
          color: COLOURS.YetAnotherBlue,
        },

        '& .pending': {
          color: COLOURS.White,
        },

        '& .pending-status': {
          fontStyle: 'italic',
          color: COLOURS.White,
        },

        '& .inactive': {
          fontStyle: 'italic',
          color: 'red',
        },
      },
  },
};

export const MuiTableCell = {
  styleOverrides: cellStyleOverrides,
};

const styleOverrides: ComponentsOverrides['MuiTable'] = {
  root: {
    // When inside the patients-table div
    '.patients-table &, .call-requests-table &, .symptoms-and-notes &, .surveys-table &':
      {
        overflow: 'hidden',
        border: `1px solid ${COLOURS.TableBorderGrey}`,
        borderRadius: '8px',
        borderCollapse: 'separate',
      },
  },
};

export const MuiTable = {
  styleOverrides,
};
