import { PropsWithRef, useCallback, useMemo } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import _ from 'lodash';

// Note: even if the major version does NOT change, these icons are prone to disappear or change...
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import { Instance } from 'hive-client-utils';
import { AnalyticsService } from 'hive-analytics-react';

function useGotoItem<T extends Instance>(
  name: string,
  path: string,
  pathParam: string,
  items: T[],
  itemId: string | undefined,
  navigate: NavigateFunction,
  offset: 1 | -1
): [string | undefined, () => void] {
  const destinationItemId = useMemo<string | undefined>(() => {
    const index = _.findIndex(items, (i: T) => i.id === itemId);
    return _.get(items, [index + offset, 'id']);
  }, [items, itemId, offset]);

  const gotoDestinationItem = useCallback(() => {
    AnalyticsService.logClick(name);
    if (destinationItemId) {
      navigate(path.replace(pathParam, destinationItemId));
    }
  }, [destinationItemId, name, navigate, path, pathParam]);

  return [destinationItemId, gotoDestinationItem];
}

export interface NavigationProps<T> {
  name: string;
  pathAll: string;
  path: string;
  pathParam: string;
  items?: T[];
  itemId: string;
  labels: {
    previous: string;
    all: string;
    next: string;
  };
  leftAction?: JSX.Element;
  rightAction?: JSX.Element;
}

export function Navigation<T extends Instance>({
  name,
  pathAll,
  path,
  pathParam,
  items,
  itemId,
  labels,
  leftAction,
  rightAction,
}: PropsWithRef<NavigationProps<T>>): JSX.Element {
  const navigate = useNavigate();
  items = items || [];

  const [previousItemId, gotoPreviousItem] = useGotoItem<T>(
    `NAVIGATION_${name}_PREVIOUS`,
    path,
    pathParam,
    items,
    itemId,
    navigate,
    -1
  );

  const [nextItemId, gotoNextItem] = useGotoItem<T>(
    `NAVIGATION_${name}_NEXT`,
    path,
    pathParam,
    items,
    itemId,
    navigate,
    1
  );

  const gotoAllItems = useCallback(() => {
    AnalyticsService.logClick(`NAVIGATION_${name}_ALL`);
    navigate(pathAll);
  }, [name, navigate, pathAll]);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      spacing={2}
      padding={2}
      className="navigation"
    >
      <Stack direction="row" flexGrow="1" justifyContent="start">
        {leftAction}
      </Stack>

      <Button
        disabled={!previousItemId}
        onClick={gotoPreviousItem}
        startIcon={<ArrowBackIcon />}
      >
        {labels.previous}
      </Button>

      <Button onClick={gotoAllItems} startIcon={<ArrowUpIcon />}>
        {labels.all}
      </Button>

      <Button
        disabled={!nextItemId}
        onClick={gotoNextItem}
        endIcon={<ArrowForwardIcon />}
      >
        {labels.next}
      </Button>

      <Stack direction="row" flexGrow="1" justifyContent="end">
        {rightAction}
      </Stack>
    </Stack>
  );
}
