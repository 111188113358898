import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DataContext } from '../../contexts/DataContext';
import { PATH_PATIENT, PATH_PATIENTS } from '../constants';
import { Navigation } from '../../components/Navigation';

export function PatientNavigation(): JSX.Element {
  const { t } = useTranslation();
  const { patients } = useContext(DataContext);
  const { patientId } = useParams();

  return (
    <Navigation
      name="PATIENTS"
      pathAll={PATH_PATIENTS}
      path={PATH_PATIENT}
      pathParam=":patientId"
      items={patients}
      itemId={patientId || ''}
      labels={{
        previous: t('content.patient.navigation.previous_patient'),
        next: t('content.patient.navigation.next_patient'),
        all: t('content.patient.navigation.all_patients'),
      }}
    />
  );
}
