import Ajv from 'ajv';
import addFormats from 'ajv-formats';

const SCHEMA_EMAIL = {
  type: 'string',
  format: 'email',
};

function createAjv(): Ajv {
  const ajv = new Ajv();
  addFormats(ajv);
  return ajv;
}

export function validateEmail(email: string): boolean {
  return createAjv().validate(SCHEMA_EMAIL, email);
}
