export const PATH_PATIENTS = '/patients';
export const PATH_PATIENT = '/patient/:patientId';

export const PATH_CALL_REQUESTS = '/call-requests';
export const PATH_CALL_REQUEST = '/call-request/:callRequestId';

export const PATH_METRICS = '/metrics';

export const PATH_SURVEYS = '/surveys';
export const PATH_SURVEY_BUILDER = '/survey_builder';

export const STORAGE_CURRENT_TAB = 'current-tab';
