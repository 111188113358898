import { ComponentsOverrides } from '@mui/material';
import { spacing } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiInputBase'] = {
  multiline: {
    '.request-summary &': {
      padding: `${spacing * 2}px`,
    },
  },
};

export const MuiInputBase = {
  styleOverrides,
};
