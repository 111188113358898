import { en as privacy_policy_text } from './PrivacyPolicy';
import { TermsOfService_en as terms_of_service_text } from './TermsOfService';

const APP_TITLE = 'eCarePD Clinician';

export const en = {
  app: {
    title: APP_TITLE,
  },
  header: {
    title: 'Clinician',
    login: 'Sign In',
    logout: 'Sign Out',
    tabs: {
      all_patients: 'All Patients',
      call_requests: 'Call Requests',
      events: 'Events',
      surveys: 'Surveys',
      survey_builder: 'Survey Builder',
    },
  },
  footer: {
    contact_us: 'Need help? Contact us.',
    contact_email: 'mailto:ecarepd@toh.ca',
    privacy_policy_title: 'Privacy Policy',
    privacy_policy: 'privacy policy',
    privacy_policy_text,
    terms_of_service_title: 'Terms of Service',
    terms_of_service: 'terms of service',
    terms_of_service_text,
  },
  common: {
    cancel: 'Cancel',
    continue: 'Continue',
    success: 'Success',
    failure: 'Failure',
    back: 'Back',
    next: 'Next',
    n_a: 'N/A',
    submit: 'Submit',
  },
  signin: {
    please_sign_in: 'Please sign in...',
  },
  content: {
    patient: {
      info: {
        title: 'Patient Info',
        labels: {
          name: 'Name',
          diagnostic_date: 'Diagnostic Date',
          email: 'Email',
          dob: 'Date of Birth',
          phone: 'Phone',
          gender: 'Gender',
        },
        n_a: 'N/A',
      },
      wearable_status: {
        title: 'Wearable Tracking Status',
      },
      wearable_information: {
        title: 'Wearable Tracker Information',
      },
      export_data: {
        title: 'Export Patient Data',
        export: 'Export',
        error: 'An error occurred while exporting data.',
      },
      navigation: {
        previous_patient: 'Previous Patient',
        next_patient: 'Next Patient',
        all_patients: 'All Patients List',
      },
    },
    patient_chart: {
      title: 'Survey History',
      display_period: 'Display Period',
      care_priorities: 'Care Priorities',
      show: 'Show',
      hide: 'Hide',
      last_month: 'Last Month',
      last_week: 'Last Week',
      last_ten_days: 'Last 10 days',
      last_five_days: 'Last 5 days',
      sleep: 'Sleep',
      activity: 'Steps',
      sleep_legend: 'Sleep Duration: ',
      sleep_raw: '{{hours}} hours {{minutes}} minutes',
      activity_legend: ' steps',
    },
    patients_table: {
      title: 'Patients ({{count}})',
      search_placeholder: 'Search',
      invite_patients: 'Invite Patients...',
      patient_name: 'Patient Name',
      assigned_to: 'Assigned to Clinician',
      recent_status: 'Recent Status',
      recent_status_invalid:
        'Inactive - Patient has not completed survey in more than {{days}} days',
      recent_status_pending:
        'Status Pending - awaiting user to activate their account',
      last_report_date: 'Last Report Date',
      last_report_date_unavailable: 'n/a',
      invite_dialog: {
        title: 'Invite Patients',
        send: 'Send Invitation',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        error: 'Could not invite patient',
      },
    },
    survey: {
      info: {
        title: 'Survey Info',
        labels: {
          name: 'Name',
          comments: 'Comments',
          email: 'Email',
          score: 'Score',
          phone: 'Phone',
        },
        n_a: 'N/A',
      },
      navigation: {
        previous_survey: 'Previous Survey',
        next_survey: 'Next Survey',
        all_surveys: 'All Surveys List',
      },
      builder: {
        first_name: 'Please enter your first name:',
        last_name: 'Please enter your last name:',
      },
    },
    survey_table: {
      title: 'Surveys ({{count}})',
      search_placeholder: 'Search',
      survey_response: 'Response',
      survey_version: 'Version',
      survey_date: 'Date Created',
    },
    call_request: {
      info: {
        labels: {
          date: 'Request Date',
          priority: 'Care Priority',
          note: 'Request Note',
          completed: 'Call Completed',
        },
      },
      navigation: {
        previous: 'Previous Call Request',
        next: 'Next Call Request',
        alls: 'All Call Requests',
      },
      complete: {
        button: 'Call Complete',
        confirmation_title: 'Call Complete',
        confirmation_message: 'Has the Call Request been completed?',
      },
      chart: {
        title: 'Symptom History',
      },
      symptoms_and_notes: {
        header: 'Symptom Ratings, Notes & Tips',
        date: 'Date',
        rating: 'Rating',
        notes: 'Notes',
        tips: 'Tips',
      },
    },
    call_requests_table: {
      title: 'Call Requests ({{count}})',
      search_placeholder: 'Search',
      columns: {
        patient_name: 'Patient Name',
        recent_status: 'Recent Status',
        date: 'Call Request Date',
        completed_date: 'Call Completed',
      },
    },
    metrics: {
      title: 'Session History',
      search: {
        label: '',
        placeholder: 'Search',
      },
      session: {
        summary: {
          session_id: 'Session ID',
          user_id: 'User ID',
          start: 'Session Start',
          duration: 'Session Duration',
          app: 'Application',
          version: 'App Version',
          platform: 'Platform',
          browser: 'Browser',
        },
      },
      events: {
        columns: {
          time: 'Time',
          event: 'Event',
          elapsed: 'Elapsed Time (ms)',
          info: 'Info',
        },
      },
      filters: {
        period_range: {
          title: 'Period Range',
          start: 'Start Date / Time',
          end: 'End Date / Time',
          last_24_hours: 'Last 24 hours',
          last_7_days: 'Last 7 days',
          last_30_days: 'Last 30 days',
          date_range: 'Date Range',
        },
        applications: {
          title: 'Application',
          names: {
            short: {
              clinician: 'Clinician',
              patient: 'Patient',
              unknown: 'Unknown',
            },
            long: {
              clinician: 'Clinician Application',
              patient: 'Patient Application',
              unknown: 'Unknown Application',
            },
          },
        },
        users: {
          title: 'Users',
          all: 'All',
        },
        sessions: {
          title: 'Sessions',
          all: 'All',
        },
        events: {
          title: 'Events',
          all: 'All',
        },
      },
    },
    wearable_daily: {
      last_updated: 'Last Updated:',
      not_connected: 'Not Connected',
      connect_header: 'Connect your wearable',
      connect_info:
        'Connect eCare PD with your wearable to see you health and fitness information with your care priorities tracking.',
      connect: 'Connect Now',
      steps_label: 'steps',
      steps_noAccess:
        'No access to steps data. To do a reset, delete the connection in the profile settings.',
      sleep_label: 'sleep duration',
      sleep_hours: 'hours',
      sleep_minutes: 'minutes',
      sleep_noAccess:
        'No access to sleep data. To do a reset, delete the connection in the profile settings.',
    },
  },
  not_implemented: 'Not implemented',
};
