import { Stack, Typography } from '@mui/material';
import { Patient, WearableDaily } from 'ecarepd-shared-utilities';
import { PropsWithRef } from 'react';
import { useTranslation } from 'react-i18next';

export interface WearableStatusProps {
  patient?: Patient;
  width?: number | string;
  flexGrow?: number | string;
}

export function WearableStatus({
  patient,
  width,
  flexGrow,
}: PropsWithRef<WearableStatusProps>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Stack width={width} flexGrow={flexGrow} className="wearable-status">
      <Typography variant="h5">
        {t('content.patient.wearable_status.title')}
      </Typography>
      <WearableDaily
        patientID={patient?.properties.patientID || ''}
        disableConnect={true}
      />
    </Stack>
  );
}
