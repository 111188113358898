import { jsx as _jsx } from "react/jsx-runtime";
import { HappyIcon, MehIcon, SadIcon, VeryHappyIcon, VerySadIcon, } from './icons';
export function ScaleView({ scale }) {
    let icon;
    switch (scale) {
        case 0:
        case '0':
            icon = _jsx(VerySadIcon, {});
            break;
        case 1:
        case '1':
            icon = _jsx(SadIcon, {});
            break;
        case 2:
        case '2':
            icon = _jsx(MehIcon, {});
            break;
        case 3:
        case '3':
            icon = _jsx(HappyIcon, {});
            break;
        case 4:
        case '4':
            icon = _jsx(VeryHappyIcon, {});
            break;
    }
    return _jsx("div", Object.assign({ className: "status-scale" }, { children: icon }));
}
