import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Typography,
  Container,
  Tab,
  Tabs,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  useConnection,
  useConnectionState,
  useLoginCallback,
} from 'hive-react-utils';
import { ConnectionService } from 'hive-client-utils';
import _ from 'lodash';

import {
  CommonDataContext,
  CommonDataContextProps,
  LanguageToggle,
  Logo,
  LogoTitle,
  useIsConnected,
} from 'ecarepd-shared-utilities';
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  matchPath,
} from 'react-router-dom';
import {
  PATH_CALL_REQUEST,
  PATH_CALL_REQUESTS,
  PATH_METRICS,
  PATH_PATIENT,
  PATH_PATIENTS,
  PATH_SURVEYS,
  STORAGE_CURRENT_TAB,
  PATH_SURVEY_BUILDER,
} from '../content/constants';
import { useDataContext } from '../contexts/DataContext';
import {
  AnalyticsService,
  LoggedConnectionService,
} from 'hive-analytics-react';

const OIDC_PATH =
  process.env.PUBLIC_URL === '/' ? '/oidc' : `${process.env.PUBLIC_URL}/oidc`;

function HeaderMenu({
  anchorEl,
  onClose,
}: {
  anchorEl: Element | null;
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const connectionState = useConnectionState();

  const handleLoginInternal = useLoginCallback(OIDC_PATH, onClose);
  const handleLogin = useCallback(() => {
    AnalyticsService.logClick('HEADER_MENU_LOGIN');
    handleLoginInternal();
  }, [handleLoginInternal]);

  const handleLogout = useCallback(() => {
    AnalyticsService.logClick('HEADER_MENU_LOGOUT');
    LoggedConnectionService.releaseBee('HEADER_MENU');
    onClose();
  }, [onClose]);

  const items = useMemo(() => {
    switch (connectionState) {
      case ConnectionService.ConnectionState.DISCONNECTED:
        return [
          <MenuItem key="sign-in" onClick={handleLogin}>
            <>{t('header.login')}</>
          </MenuItem>,
        ];

      case ConnectionService.ConnectionState.CONNECTED:
      case ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN:
        return [
          <MenuItem key="sign-out" onClick={handleLogout}>
            <>{t('header.logout')}</>
          </MenuItem>,
        ];
    }

    return [];
  }, [connectionState, t, handleLogin, handleLogout]);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={!!anchorEl}
      onClose={onClose}
    >
      {items}
    </Menu>
  );
}

function MenuAndLanguage(): JSX.Element {
  const connection = useConnection();
  const isConnected = useIsConnected();

  const username = _.get(connection, 'username', '');

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

  const handleOpenMenu = useCallback((event: any) => {
    AnalyticsService.logClick('HEADER_MENU_SHOWN');
    setMenuAnchor(event.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  if (!isConnected) {
    return <LanguageToggle />;
  }

  return (
    <>
      <HeaderMenu anchorEl={menuAnchor} onClose={handleCloseMenu} />

      <Stack direction="column" spacing={0}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleOpenMenu}
        >
          <Stack direction="row" spacing={1}>
            <Typography>{username}</Typography>
            <MenuIcon />
          </Stack>
        </IconButton>

        <LanguageToggle />
      </Stack>
    </>
  );
}

const PATHS_TO_TAB_VALUE = [
  [PATH_CALL_REQUEST, PATH_CALL_REQUESTS],
  [PATH_CALL_REQUESTS, PATH_CALL_REQUESTS],
  [PATH_PATIENT, PATH_PATIENTS],
  [PATH_PATIENTS, PATH_PATIENTS],
  [PATH_METRICS, PATH_METRICS],
  [PATH_SURVEYS, PATH_SURVEYS],
  [PATH_SURVEY_BUILDER, PATH_SURVEY_BUILDER],
];

function HeaderTabs(): JSX.Element | null {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isConnected = useIsConnected();
  const { hasAnalyticsReaderRole, hasAdministratorRole } = useDataContext();
  const { featureFlags } =
    useContext<CommonDataContextProps>(CommonDataContext);

  // Determine which tab based on the location
  const location = useLocation();
  const currentTab = useMemo(() => {
    for (const [pattern, tabValue] of PATHS_TO_TAB_VALUE) {
      if (matchPath(pattern, location.pathname)) {
        return tabValue;
      }
    }

    // Patients list by default
    return PATH_PATIENTS;
  }, [location]);

  const onTabChange = useCallback(
    (_event: any, value: string) => {
      navigate(value);
      localStorage.setItem(STORAGE_CURRENT_TAB, value);
    },
    [navigate]
  );

  if (!isConnected) {
    return null;
  }

  return (
    <Stack className="header-tabs" flexGrow="1">
      <Tabs value={currentTab} onChange={onTabChange}>
        <Tab value={PATH_PATIENTS} label={t('header.tabs.all_patients')} />
        <Tab
          value={PATH_CALL_REQUESTS}
          label={t('header.tabs.call_requests')}
        />
        {hasAnalyticsReaderRole && (
          <Tab value={PATH_METRICS} label={t('header.tabs.events')} />
        )}
        {featureFlags.SURVEY && hasAdministratorRole && (
          <Tab value={PATH_SURVEYS} label={t('header.tabs.surveys')} />
        )}
        {featureFlags.SURVEY && hasAdministratorRole && (
          <Tab
            value={PATH_SURVEY_BUILDER}
            label={t('header.tabs.survey_builder')}
          />
        )}
      </Tabs>
    </Stack>
  );
}

export default function Header(): JSX.Element {
  return (
    <header>
      <Paper>
        <AppBar position="static">
          <Toolbar>
            <RouterLink to="/">
              <Logo />
              <LogoTitle />
            </RouterLink>

            <Container />

            <HeaderTabs />

            <MenuAndLanguage />
          </Toolbar>
        </AppBar>
      </Paper>
    </header>
  );
}
