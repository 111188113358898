import { Stack } from '@mui/material';
import { RecentStatus } from 'ecarepd-shared-utilities';
import _ from 'lodash';
import { Fragment, PropsWithRef } from 'react';
import { RecentStatusView } from './RecentStatusView';

export interface RecentStatusesProps {
  recentStatus?: RecentStatus[];
  selectedPriorityId?: string;
}

export function RecentStatuses({
  recentStatus,
  selectedPriorityId,
}: PropsWithRef<RecentStatusesProps>): JSX.Element {
  return (
    <Stack direction="row" spacing={0} flexWrap="wrap">
      {_.map(recentStatus || [], (r: RecentStatus) => (
        <Fragment key={r.priorityID}>
          <RecentStatusView
            recentStatus={r}
            highlighted={r.priorityID === selectedPriorityId}
          />
        </Fragment>
      ))}
    </Stack>
  );
}
