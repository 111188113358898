import { createContext, useContext, useMemo } from 'react';
import {
  CallRequest,
  CliniciansById,
  Patient,
  SurveyResults,
  useCallRequests,
  useClinicians,
  usePatients,
  useSurveyResults,
} from 'ecarepd-shared-utilities';
import moment from 'moment';
import { useHasRole } from 'hive-react-utils';
import { Clinician } from 'ecarepd-shared-utilities/src/types/Clinician';

export interface DataContextProps {
  clinicians: Clinician[];
  cliniciansById: CliniciansById;
  patients: Patient[];
  surveys: SurveyResults[];
  callRequests: CallRequest[];
  hasClinicianRole?: boolean;
  hasAdministratorRole?: boolean;
  hasAnalyticsReaderRole?: boolean;
}

export const DataContext = createContext<DataContextProps>({
  clinicians: [],
  cliniciansById: {},
  patients: [],
  surveys: [],
  callRequests: [],
});

export function DataContextProvider({
  children,
}: {
  children: null | JSX.Element;
}) {
  // IMPORTANT: just keep data that does NOT change often and that can be used often.

  const hasClinicianRole = useHasRole('clinician');
  const hasAdministratorRole = useHasRole('administrator');
  const hasAnalyticsReaderRole = useHasRole('analyticsReader');

  const { clinicians, cliniciansById } = useClinicians();
  const patients = usePatients(cliniciansById);
  const surveys = useSurveyResults(); //new
  const callRequests = useCallRequests(
    patients || [],
    moment().startOf('day').toISOString()
  );

  const value = useMemo(
    () => ({
      patients: patients || [],
      surveys: surveys || [],
      clinicians,
      cliniciansById,
      callRequests,
      hasClinicianRole,
      hasAdministratorRole,
      hasAnalyticsReaderRole,
    }),
    [
      patients,
      surveys,
      clinicians,
      cliniciansById,
      callRequests,
      hasClinicianRole,
      hasAdministratorRole,
      hasAnalyticsReaderRole,
    ]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export function useDataContext() {
  return useContext(DataContext);
}
