import { useContext, useMemo } from 'react';
import { useInstancesByQuery } from 'hive-react-utils';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { CommonDataContext } from '../contexts';
import moment from 'moment';
export function useCallRequests(patients, completedStartTime) {
    const active = useInstancesByQuery('ecarepd', 'activeCallRequests', 'callRequest', {
        debounce: true,
        debounceWait: 1000,
    });
    const recentCompleted = useInstancesByQuery('ecarepd', 'recentCompletedCallRequests', 'callRequest', {
        debounce: true,
        debounceWait: 1000,
    }, moment(completedStartTime).toISOString());
    const raw = useMemo(() => {
        if (!active) {
            return recentCompleted || [];
        }
        if (!recentCompleted) {
            return active || [];
        }
        return _fp.flow([
            // Comment to keep this on single lines
            _fp.compact,
            _fp.uniqBy('id'),
        ])([...active, ...recentCompleted]);
    }, [active, recentCompleted]);
    const patientsById = useMemo(() => _fp.flow([
        // This comment forces formatting to have one call per line
        _fp.groupBy('properties.patientID'),
        _fp.mapValues(_fp.first),
    ])(patients), [patients]);
    const { symptomPrioritiesById } = useContext(CommonDataContext);
    const processed = useMemo(() => {
        if (!raw) {
            return [];
        }
        return _fp.flow([
            _fp.map((r) => (Object.assign(Object.assign({}, r), { properties: Object.assign(Object.assign({}, r.properties), { patient: _.get(patientsById, [r.properties.patientID, 'properties']), priority: _.get(symptomPrioritiesById, [
                        r.properties.priorityID,
                        'properties',
                    ]) }) }))),
            _fp.sortBy('properties.date'),
        ])(raw);
    }, [raw, patientsById, symptomPrioritiesById]);
    return processed;
}
