import { ComponentsOverrides } from '@mui/material';
import { spacing } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiCheckbox'] = {
  root: {
    '.metrics &': {
      padding: `${spacing}px`,
    },
  },
};

export const MuiCheckbox = {
  styleOverrides,
};
