import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { CallRequestNavigation } from './CallRequestNavigation';
import { PatientInfo } from '../patient/PatientInfo';
import { RequestSummary } from './RequestSummary';
import { SymptomsAndNotes } from './SymptomsAndNotes';
import { useCallRequestById } from '../../hooks/useCallRequestById';
import { usePatientFromCallRequest } from '../../hooks/usePatientFromCallRequest';
import { SymptomHistory } from './SymptomHistory';
import { useContext } from 'react';
import { DataContext } from '../../contexts/DataContext';

export function CallRequest(): JSX.Element {
  const { callRequestId } = useParams();
  const { hasClinicianRole } = useContext(DataContext);
  const callRequest = useCallRequestById(callRequestId);
  const patient = usePatientFromCallRequest(callRequest);

  return (
    // We do not use a spacing between each element because we want no spacing between navigation and first item
    // So we use paddingTop to space the items individually
    <Stack padding={4} spacing={0}>
      <CallRequestNavigation />

      <Stack direction="row">
        <PatientInfo patient={patient} flexGrow="1" linkToPatient={true} />
        <RequestSummary callRequest={callRequest} flexGrow="1" />
      </Stack>

      {hasClinicianRole && (
        <>
          <Stack paddingTop={4}>
            <SymptomHistory
              patient={patient}
              priorityId={callRequest?.properties.priorityID}
            />
          </Stack>

          <Stack paddingTop={4}>
            <SymptomsAndNotes callRequest={callRequest} />
          </Stack>
        </>
      )}
    </Stack>
  );
}
