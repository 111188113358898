import MaterialTable, { Column, Options } from '@material-table/core';
import { Container, Stack, Typography } from '@mui/material';
import {
  CallRequest,
  COLOURS,
  PatientPriorityResponse,
  usePatientPriorityResponsesByPriorityID,
  useResponseCareTipsByResponseIds,
} from 'ecarepd-shared-utilities';
import _ from 'lodash';
import _fp from 'lodash/fp';
import moment from 'moment';
import { PropsWithRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RecentStatusView } from '../../components/RecentStatusView';
import { ReactComponent as Positive } from '../../assets/Positive.svg';
import { ReactComponent as Negative } from '../../assets/Negative.svg';

const THUMB_SIZE = '48px';

function renderDate(
  fieldName: string,
  notAvailable: string,
  r: PatientPriorityResponse
) {
  const date = _.get(r, fieldName);
  const m = moment(date);
  return date && m.isValid() ? m.format('LL') : notAvailable;
}

function renderTip(r: PatientPriorityResponse) {
  const tip = _.get(r, 'properties.tip');
  const rating = _.get(r, 'properties.tipRating');
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography>{tip}</Typography>

      {rating === 1 && (
        <Positive
          width={THUMB_SIZE}
          height={THUMB_SIZE}
          color={COLOURS.ThumbUp}
        />
      )}

      {rating === -1 && (
        <Negative
          width={THUMB_SIZE}
          height={THUMB_SIZE}
          color={COLOURS.ThumbDown}
        />
      )}
    </Stack>
  );
}

export interface SymptomsAndNotesProps {
  callRequest?: CallRequest;
}

export function SymptomsAndNotes({
  callRequest,
}: PropsWithRef<SymptomsAndNotesProps>): JSX.Element {
  const { t } = useTranslation();
  const priorities = usePatientPriorityResponsesByPriorityID(
    callRequest?.properties.patient?.patientID || '',
    callRequest?.properties.priorityID || '',
    10
  );

  const responseIds = useMemo(() => _.map(priorities, 'id'), [priorities]);
  const careTips = useResponseCareTipsByResponseIds(responseIds);

  const processedPriorities = useMemo(() => {
    const careTipsByResponseId = _fp.flow([
      _fp.groupBy('properties.responseID'),
      _fp.mapValues(_fp.first),
    ])(careTips);

    return _.map(priorities, (p: PatientPriorityResponse) => ({
      ...p,
      properties: {
        ...p.properties,
        tip: _.get(careTipsByResponseId, `${p.id}.properties.tip`, ''),
        tipRating: _.get(careTipsByResponseId, `${p.id}.properties.rating`, ''),
      },
    }));
  }, [priorities, careTips]);

  const renderRecentStatus = useCallback((p: PatientPriorityResponse) => {
    return <RecentStatusView recentStatus={p.properties} />;
  }, []);

  const renderResponseDate = useCallback(
    (p: PatientPriorityResponse) =>
      renderDate('properties.date2', t('not_available'), p),
    [t]
  );

  const columns = useMemo<Array<Column<PatientPriorityResponse>>>(
    () => [
      {
        title: t('content.call_request.symptoms_and_notes.date'),
        width: '200px',
        field: 'properties.date2',
        render: renderResponseDate,
      },
      {
        title: t('content.call_request.symptoms_and_notes.rating'),
        width: `200px`,
        field: 'properties.response',
        render: renderRecentStatus,
      },
      {
        title: t('content.call_request.symptoms_and_notes.notes'),
        field: 'properties.note',
      },
      {
        title: t('content.call_request.symptoms_and_notes.tips'),
        field: 'properties.tip',
        render: renderTip,
      },
    ],
    [t, renderRecentStatus, renderResponseDate]
  );

  const options: Options<PatientPriorityResponse> = useMemo(
    () => ({
      showTitle: false,
      search: false,
      paging: false,
      sorting: false,
    }),
    []
  );

  return (
    <Stack flexGrow="1" spacing={2} className="symptoms-and-notes">
      <Typography variant="h5">
        {t('content.call_request.symptoms_and_notes.header')}
      </Typography>

      <Stack paddingLeft={2} paddingRight={2}>
        <MaterialTable
          components={{
            Toolbar: Container, // To remove the toolbar aread
          }}
          columns={columns}
          data={processedPriorities}
          options={options}
        />
      </Stack>
    </Stack>
  );
}
