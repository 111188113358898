import MaterialTable, {
  Column,
  Localization,
  Options,
} from '@material-table/core';
import { Typography } from '@mui/material';
import { CallRequest } from 'ecarepd-shared-utilities';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RecentStatuses } from '../../components/RecentStatuses';
import { DataContext } from '../../contexts/DataContext';
import { PATH_CALL_REQUEST } from '../constants';

function renderDate(fieldName: string, notAvailable: string, r: CallRequest) {
  const date = _.get(r, fieldName);
  const m = moment(date);
  return (
    <Typography variant="body1">
      {date && m.isValid() ? m.format('LL') : notAvailable}
    </Typography>
  );
}

export function CallRequests(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { callRequests } = useContext(DataContext);

  const title = t('content.call_requests_table.title', {
    count: _.size(callRequests),
  });

  const renderRecentStatus = useCallback((r: CallRequest) => {
    return (
      <RecentStatuses
        recentStatus={r.properties.patient?.recentStatus}
        selectedPriorityId={r.properties.priorityID}
      />
    );
  }, []);

  const renderPatientName = useCallback((r: CallRequest) => {
    return (
      <Typography variant="body2">{r.properties.patient?.name}</Typography>
    );
  }, []);

  const renderCallRequestDate = useCallback(
    (r: CallRequest) => renderDate('properties.date', t('not_available'), r),
    [t]
  );

  const renderCompletedDate = useCallback(
    (r: CallRequest) => renderDate('properties.completed', '', r),
    []
  );

  const columns: Array<Column<CallRequest>> = useMemo(
    () => [
      {
        title: t('content.call_requests_table.columns.patient_name'),
        width: '20%',
        field: 'properties.patient.name',
        render: renderPatientName,
      },
      {
        title: t('content.call_requests_table.columns.recent_status'),
        width: '40%',
        field: 'properties.patient.recentStatus',
        render: renderRecentStatus,
        sorting: false,
      },
      {
        title: t('content.call_requests_table.columns.date'),
        width: '15%',
        field: 'properties.date',
        render: renderCallRequestDate,
      },
      {
        title: t('content.call_requests_table.columns.completed_date'),
        width: '15%',
        field: 'properties.completed',
        render: renderCompletedDate,
      },
    ],
    [
      renderCallRequestDate,
      renderCompletedDate,
      renderPatientName,
      renderRecentStatus,
      t,
    ]
  );

  const options: Options<CallRequest> = useMemo(
    () => ({
      paging: false,
      sorting: true,
    }),
    []
  );

  const localization: Localization = {
    toolbar: {
      searchPlaceholder: t('content.call_requests_table.search_placeholder'),
    },
  };

  const onRowClick = useCallback(
    (event: any, r?: CallRequest) => {
      if (!r?.id) {
        return;
      }

      navigate(PATH_CALL_REQUEST.replace(':callRequestId', r.id));
    },
    [navigate]
  );

  return (
    <div className="call-requests-table">
      <MaterialTable
        onRowClick={onRowClick}
        isLoading={callRequests == null}
        title={title}
        columns={columns}
        data={callRequests || []}
        options={options}
        localization={localization}
      />
    </div>
  );
}
