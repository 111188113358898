import { ComponentsOverrides } from '@mui/material';
import { COLOURS, spacing } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiTab'] = {
  root: {
    'header div.header-tabs &': {
      minHeight: 'unset',
      paddingTop: spacing,
      paddingBottom: spacing,
      backgroundColor: COLOURS.White,
    },
  },
};

export const MuiTab = {
  styleOverrides,
};

const tabsStyleOverrides: ComponentsOverrides['MuiTabs'] = {
  root: {
    maxWidth: 'unset',
    minHeight: 'unset',
    'header div.header-tabs & .Mui-selected': {
      minHeight: 'unset',
      color: COLOURS.Primary,
      backgroundColor: COLOURS.White,
      margin: 'unset',
      borderRadius: 0,
    },
    '.MuiTabs-indicator': {
      backgroundColor: COLOURS.Primary,
    },
  },
};

export const MuiTabs = {
  styleOverrides: tabsStyleOverrides,
};
