import { AppNames } from 'ecarepd-shared-utilities';
import { Event, Session } from 'hive-analytics-react';
import _ from 'lodash';
import _fp from 'lodash/fp';
import moment from 'moment';
import { FilterContextProps } from './FilterContext';
import { TFunction } from 'i18next';

export const formatData = _fp.flow([
  (v: any) => JSON.stringify(v, null, 4),
  _fp.replace(/\n/g, '<br>'),
  // _fp.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'),
  _fp.replace(/ /g, '&nbsp;'),
]);

const REGEX_UUID =
  /([0-9a-fA-F]{8})-([0-9a-fA-F]{4})-([0-9a-fA-F]{4})-([0-9a-fA-F]{4})-([0-9a-fA-F]{12})/;

export const formatShortUUid = (uuid: string = '') => {
  const match = REGEX_UUID.exec(uuid);
  if (!match) {
    return uuid;
  }

  const [, part1] = match;
  return part1;
};

export function formatDateAndTime(
  dateTime?: moment.Moment | string,
  withMilliseconds: boolean = false
): string {
  if (!dateTime) {
    return '';
  }

  if (withMilliseconds) {
    return moment(dateTime).format('YYYY-MM-DD HH:mm:ss.SSS');
  } else {
    return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
  }
}

export function formatDuration(duration?: moment.Duration): string {
  if (!duration) {
    return '';
  }

  // TODO: localize this, not sure how

  const seconds = duration.as('seconds');

  let format;
  if (seconds >= 86400) {
    format = 'D[d] H[h] m[m] s[s]';
  } else if (seconds >= 3600) {
    format = 'H[h] m[m] s[s]';
  } else if (seconds >= 60) {
    format = 'm[m] s[s]';
  } else {
    format = 'S[s]';
  }

  return moment.utc(duration.as('milliseconds')).format(format);
}

export function formatAppName(t: TFunction, session?: Session) {
  switch (session?.appName) {
    case AppNames.Patient:
      return t('content.metrics.filters.applications.names.short.patient');
    case AppNames.Clinician:
      return t('content.metrics.filters.applications.names.short.clinician');
    default:
      return t('content.metrics.filters.applications.names.short.unknown');
  }
}

export function formatAppNameForFilters(t: TFunction, appName: AppNames) {
  switch (appName) {
    case AppNames.Patient:
      return t('content.metrics.filters.applications.names.long.patient');
    case AppNames.Clinician:
      return t('content.metrics.filters.applications.names.long.clinician');
    default:
      return t('content.metrics.filters.applications.names.long.unknown');
  }
}

type ValueFormatter = (v: any) => string;
interface ValueFormatters {
  [key: string]: ValueFormatter;
}

const VALUE_FORMATTERS: ValueFormatters = {
  string: _.identity,
  number: (v: number) => v.toString(),
  object: JSON.stringify,
  default: JSON.stringify,
};

function formatValue(v: any) {
  const formatter = _.get(VALUE_FORMATTERS, typeof v, VALUE_FORMATTERS.default);
  return formatter(v);
}

const valueMatches = (search: string) => (value: string) => {
  value = _.toLower(_.deburr(value));
  return _.includes(value, search);
};

const eventMatches = ({
  search,
  appNames,
  userIds,
  sessionIds,
  eventIds,
}: FilterContextProps) => {
  search = _.toLower(_.deburr(search));
  return (e: Event) => {
    if (!appNames.has(e.properties.appName)) {
      return false;
    }

    if (!userIds.has(e.properties.userId || '')) {
      return false;
    }

    if (!sessionIds.has(e.properties.sessionId)) {
      return false;
    }

    if (!eventIds.has(e.properties.eventId)) {
      return false;
    }

    const values = _fp.flow([
      // Use some visible fields only
      _fp.pick([
        'eventId',
        'appName',
        'appVersion',
        'userAgent',
        'elapsed',
        'data',
      ]),
      _fp.values,
      _fp.map(formatValue),
    ])(e.properties);

    return _.some(values, valueMatches(search));
  };
};

export const filterEvents = (events: Event[], filter: FilterContextProps) => {
  return _.filter(events, eventMatches(filter));
};

const sessionsMatches = ({
  appNames,
  userIds,
  sessionIds,
  eventIds,
}: FilterContextProps) => {
  return (s: Session) => {
    if (!s.appName || !appNames.has(s.appName)) {
      return false;
    }

    if (!userIds.has(s.userId || '')) {
      return false;
    }

    if (!s.id || !sessionIds.has(s.id)) {
      return false;
    }

    if (_.isEmpty(_.intersection(Array.from(eventIds), s.eventIds))) {
      return false;
    }

    // Don't use the search for sessions, since we might be hiding sessions where
    // the events (which we might not have here), would indeed show up.
    return true;
  };
};

export const filterSessions = (
  sessions: Session[],
  filter: FilterContextProps
) => {
  return _.filter(sessions, sessionsMatches(filter));
};
