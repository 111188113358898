import { ComponentsOverrides } from '@mui/material';
import { COLOURS, spacing } from 'ecarepd-shared-utilities';

const mainStyleOverrides: ComponentsOverrides['MuiAccordion'] = {
  root: {
    '.metrics &': {
      '::before': {
        // Hide the line that shows up before when NOT expanded
        opacity: 0,
      },
    },
  },
};

export const MuiAccordion = {
  styleOverrides: mainStyleOverrides,
};

const summaryStyleOverrides: ComponentsOverrides['MuiAccordionSummary'] = {
  root: {
    '.metrics &': {
      flexDirection: 'row-reverse',
      backgroundColor: COLOURS.OddRowBackground,
      minHeight: 'unset',
      paddingTop: '0px',
      paddingBottom: '0px',
      borderRadius: '4px',
      '.MuiTypography-body1': {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '16px',
        letterSpacing: '-0.175px',
        color: COLOURS.DarkBlue,
      },
      '.MuiTypography-body2': {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '-0.175px',
        color: COLOURS.Black,
      },
    },
  },
  content: {
    '.metrics &': {
      minHeight: 'unset',

      // Force a common margin regardless of expanded or not
      margin: `${spacing * 2}px 0`,
    },
  },
  expandIconWrapper: {
    '.metrics &': {
      minHeight: 'unset',
      paddingTop: '0px',
      paddingBottom: '0px',
      color: COLOURS.RoyalBlue,
      '&.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
    },
  },
};

export const MuiAccordionSummary = {
  styleOverrides: summaryStyleOverrides,
};

const detailsStyleOverrides: ComponentsOverrides['MuiAccordionDetails'] = {
  root: {
    '.metrics &': {
      marginTop: `${spacing * 1}px`,
    },
  },
};

export const MuiAccordionDetails = {
  styleOverrides: detailsStyleOverrides,
};
