import { Stack, Typography } from '@mui/material';
import {
  CommonDataContext,
  CommonDataContextProps,
  RecentStatus,
  ScaleView,
} from 'ecarepd-shared-utilities';
import _ from 'lodash';
import { PropsWithRef, useContext } from 'react';

export interface RecentStatusViewProps {
  recentStatus: RecentStatus;
  highlighted?: boolean;
}

export function RecentStatusView({
  recentStatus,
  highlighted,
}: PropsWithRef<RecentStatusViewProps>): JSX.Element {
  const { symptomPrioritiesById } =
    useContext<CommonDataContextProps>(CommonDataContext);
  const priority = _.get(symptomPrioritiesById, recentStatus.priorityID);
  const priorityName = _.get(priority, 'properties.name');

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      whiteSpace="nowrap"
      paddingRight={4}
      className="recent-status"
    >
      <Typography variant={highlighted ? 'body2' : 'body1'}>
        {priorityName}
      </Typography>
      <ScaleView scale={recentStatus.response} />
    </Stack>
  );
}
