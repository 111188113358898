import { ComponentsOverrides, Theme } from '@mui/material';
import { COLOURS, theme as baseTheme } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides<Theme>['MuiTypography'] = {
  h5: {
    color: baseTheme.palette.primary.dark,
    fontStyle: 'italic',
    '.patient-chart &': {
      color: baseTheme.palette.primary.dark,
      fontStyle: 'italic',
    },
  },
  h6: {
    '.request-summary &': {
      fontStyle: 'italic',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      color: COLOURS.DarkBlue,
    },
  },
  body1: {
    'header .MuiIconButton-root &': {
      textTransform: 'capitalize',
    },
    '.patient-info &': {
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      color: COLOURS.DarkBlue,
    },
    '.recent-status &': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      color: COLOURS.DarkGrey,
    },
    '.patient-chart &': {
      color: baseTheme.palette.primary.dark,
      fontStyle: 'italic',
    },
  },
  body2: {
    '.patient-info &': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      color: COLOURS.DarkerGrey,
      wordBreak: 'break-word',
    },
    '.recent-status &': {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '18px',
      color: COLOURS.DarkerGrey,
    },
  },
};

export const MuiTypography = {
  styleOverrides,
};
