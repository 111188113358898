import { useConnection } from 'hive-react-utils';
import { useEffect, useMemo, useState } from 'react';
import { utils } from 'hive-client-utils';
import _fp from 'lodash/fp';
import _ from 'lodash';
export function useClinicians() {
    const connection = useConnection();
    const [clinicians, setClinicians] = useState([]);
    useEffect(() => {
        if (!connection || !connection.bee) {
            setClinicians([]);
            return;
        }
        connection.bee.actions
            .invoke('fetchClinicians')
            .then(utils.waitForInvocationReaction(connection.bee, (r) => { var _a; return ((_a = r === null || r === void 0 ? void 0 : r.details) === null || _a === void 0 ? void 0 : _a.message) || []; }))
            .then((newClinicians) => _.sortBy(newClinicians, 'name'))
            .then(setClinicians)
            .catch((error) => {
            console.error(error);
            setClinicians([]);
        });
    }, [connection]);
    const [cliniciansById, setCliniciansById] = useState({});
    useEffect(() => setCliniciansById(_fp.flow([
        // This comment prevents formatters of placing all on a single line
        _fp.groupBy('id'),
        _fp.mapValues(_fp.first),
    ])(clinicians)), [clinicians]);
    return useMemo(() => ({ clinicians, cliniciansById }), [clinicians, cliniciansById]);
}
