import { useInstancesByQuery } from 'hive-react-utils';
import _ from 'lodash';
import { useEffect, useState } from 'react';
export function usePatientPriorityResponsesByPriorityID(patientID, priorityID, limit) {
    const rawPriorities = useInstancesByQuery('ecarepd', 'getPatientPriorityResponseByPriorityIDAndPatientID', 'patientPriorityResponse', {
        // Give a little debounce to prevent jerkiness while a survey is saved (multiple items usually)
        debounce: true,
        debounceWait: 1000,
    }, priorityID, patientID, limit);
    // Make sure we ignore pending status
    const [priorities, setPriorities] = useState([]);
    useEffect(() => {
        if (!rawPriorities ||
            _.get(rawPriorities, 'status') !== "done" /* ContentObserver.status.DONE */) {
            return;
        }
        setPriorities(rawPriorities);
    }, [rawPriorities]);
    return priorities;
}
