import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Session as ISession, useSessionsByDate } from 'hive-analytics-react';
import {
  ChangeEvent,
  Fragment,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Filters } from './filters/Filters';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import moment from 'moment';
import _fp from 'lodash/fp';
import _ from 'lodash';
import { Session } from './Session';
import { useTranslation } from 'react-i18next';
import { useSelectedValues } from './filters/utilities';
import { AppNames } from 'ecarepd-shared-utilities';
import { FilterContextProvider } from './FilterContext';
import { filterSessions } from './utilities';

const APP_NAMES = [AppNames.Clinician, AppNames.Patient];

export function Metrics(): JSX.Element {
  const { t } = useTranslation();
  const [startTime, setStartTime] = useState<moment.Moment>(() =>
    moment().startOf('day')
  );
  const [endTime, setEndTime] = useState<moment.Moment>(() =>
    moment().endOf('day')
  );

  const sessions = useSessionsByDate(startTime, endTime, {
    debounce: true,
    debounceWait: 5000,
    debounceMaxWait: 30000,
  });

  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handleOpenFilter = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setFilterAnchorEl(event.currentTarget);
    },
    []
  );

  const handleCloseFilter = useCallback(() => {
    setFilterAnchorEl(null);
  }, []);

  const [search, setSearch] = useState('');

  const userIds = useMemo(
    () =>
      _fp.flow([
        // Forces one op per line
        _fp.groupBy('userId'),
        _fp.keys,
        _fp.sortBy(_fp.identity),
      ])(sessions),
    [sessions]
  );

  const sessionIds = useMemo(
    () =>
      _fp.flow([
        // Forces one op per line
        _fp.groupBy('id'),
        _fp.keys,
        _fp.sortBy(_fp.identity),
      ])(sessions),
    [sessions]
  );

  const eventIds = useMemo(
    () =>
      _fp.flow([
        // Forces one op per line
        _fp.map('eventIds'),
        _fp.flatten,
        _fp.sortBy(_fp.identity),
        _fp.uniq,
      ])(sessions),
    [sessions]
  );

  const [selectedAppNames, setSelectedAppsNames] = useSelectedValues(APP_NAMES);
  const [selectedUserIds, setSelectedUserIds] = useSelectedValues(userIds);
  const [selectedSessionIds, setSelectedSessionIds] =
    useSelectedValues(sessionIds);
  const [selectedEventIds, setSelectedEventIds] = useSelectedValues(eventIds);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChanged = useCallback(
    _.debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    }, 500),
    []
  );

  const filteredSessions = useMemo(
    () =>
      filterSessions(sessions || [], {
        startTime,
        endTime,
        search,
        appNames: selectedAppNames,
        userIds: selectedUserIds,
        sessionIds: selectedSessionIds,
        eventIds: selectedEventIds,
      }),
    [
      endTime,
      search,
      selectedAppNames,
      selectedEventIds,
      selectedSessionIds,
      selectedUserIds,
      sessions,
      startTime,
    ]
  );

  return (
    <FilterContextProvider
      startTime={startTime}
      endTime={endTime}
      search={search}
      appNames={selectedAppNames}
      userIds={selectedUserIds}
      sessionIds={selectedSessionIds}
      eventIds={selectedEventIds}
    >
      <Stack className="metrics" padding={8} spacing={4}>
        <Stack direction="row" alignItems="center" justifyContent="left">
          <Typography variant="h5">{t('content.metrics.title')}</Typography>

          <IconButton onClick={handleOpenFilter}>
            <FilterAltIcon color="primary" />
          </IconButton>

          <Stack flexGrow="1" />

          <Box>
            <TextField
              variant="standard"
              label={t('content.metrics.search.label')}
              placeholder={t('content.metrics.search.placeholder') || undefined}
              inputProps={{ type: 'search' }}
              onChange={onSearchChanged}
            />
          </Box>
        </Stack>

        <Filters
          open={!!filterAnchorEl}
          anchorEl={filterAnchorEl}
          onClose={handleCloseFilter}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          appNames={APP_NAMES}
          selectedAppNames={selectedAppNames}
          setSelectedAppNames={setSelectedAppsNames}
          userIds={userIds}
          selectedUserIds={selectedUserIds}
          setSelectedUserIds={setSelectedUserIds}
          sessionIds={sessionIds}
          selectedSessionIds={selectedSessionIds}
          setSelectedSessionIds={setSelectedSessionIds}
          eventIds={eventIds}
          selectedEventIds={selectedEventIds}
          setSelectedEventIds={setSelectedEventIds}
        />

        <Stack spacing={1}>
          {_.isEmpty(sessions) && (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          )}

          {_.map(filteredSessions, (session: ISession) => (
            <Fragment key={session.id}>
              <Session session={session} />
            </Fragment>
          ))}
        </Stack>
      </Stack>
    </FilterContextProvider>
  );
}
