import { CallRequest, Patient } from 'ecarepd-shared-utilities';
import _ from 'lodash';
import { useContext, useMemo } from 'react';
import { DataContext } from '../contexts/DataContext';

export function usePatientFromCallRequest(
  callRequest?: CallRequest
): Patient | undefined {
  const { patients } = useContext(DataContext);

  return useMemo(
    () =>
      _.find(
        patients,
        (p: Patient) =>
          p.properties.patientID === callRequest?.properties.patientID
      ),
    [callRequest, patients]
  );
}
