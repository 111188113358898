import { CircularProgress, Stack } from '@mui/material';
import {
  Patient,
  PatientChart,
  usePatientById,
} from 'ecarepd-shared-utilities';
import { PropsWithRef } from 'react';
import { useParams } from 'react-router-dom';
import { WearableStatus } from './WearableStatus';
import { ExportPatientData } from './ExportPatientData';
import { PatientInfo } from './PatientInfo';
import { PatientNavigation } from './PatientNavigation';

interface ChartProps {
  patient: Patient;
}
function Chart({ patient }: PropsWithRef<ChartProps>): JSX.Element {
  return <PatientChart patient={patient} />;
}

export default function PatientView(): JSX.Element {
  const { patientId } = useParams();
  const patient = usePatientById(patientId);

  return (
    <Stack
      direction="column"
      flexGrow="1"
      height="100%"
      paddingLeft={4}
      paddingRight={4}
    >
      <PatientNavigation />

      <Stack direction="row" justifyContent="space-between">
        <PatientInfo patient={patient} width={450} />
        <WearableStatus patient={patient} />
        <ExportPatientData patient={patient} />
      </Stack>

      {patient && <Chart patient={patient} />}
      {!patient && <CircularProgress color="primary" />}
    </Stack>
  );
}
