import { Navigate, Route, Routes } from 'react-router-dom';
import {
  PATH_CALL_REQUEST,
  PATH_CALL_REQUESTS,
  PATH_METRICS,
  PATH_PATIENT,
  PATH_PATIENTS,
  PATH_SURVEYS,
  PATH_SURVEY_BUILDER,
} from './constants';
import Patient from './patient/Patient';
import Patients from './patients/Patients';
import { CallRequests } from './callRequests/CallRequests';
import { CallRequest } from './callRequests/CallRequest';
import { Metrics } from './metrics/Metrics';
import Surveys from './surveys/Surveys';
import { useContext } from 'react';
import { DataContext } from '../contexts/DataContext';
import {
  CommonDataContext,
  CommonDataContextProps,
} from 'ecarepd-shared-utilities';
import SurveyBuilder from './surveys/SurveyBuilder';

export default function Content(): JSX.Element {
  const { hasAdministratorRole } = useContext(DataContext);
  const { featureFlags } =
    useContext<CommonDataContextProps>(CommonDataContext);
  return (
    <Routes>
      {!hasAdministratorRole && (
        <Route path={PATH_PATIENT} element={<Patient />} />
      )}
      {featureFlags.SURVEY && hasAdministratorRole && (
        <>
          <Route path={PATH_SURVEYS} element={<Surveys />} />
          <Route path={PATH_SURVEY_BUILDER} element={<SurveyBuilder />} />
        </>
      )}
      <Route path={PATH_PATIENTS} element={<Patients />} />
      <Route path={PATH_CALL_REQUEST} element={<CallRequest />} />
      <Route path={PATH_CALL_REQUESTS} element={<CallRequests />} />
      <Route path={PATH_METRICS} element={<Metrics />} />
      <Route path="*" element={<Navigate to={PATH_PATIENTS} />} />
    </Routes>
  );
}
