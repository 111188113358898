import { ComponentsOverrides } from '@mui/material';
import { theme as baseTheme } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiPaper'] = {
  root: {
    // When inside the patients-table div
    '.patients-table &, .call-requests-table &, .surveys-table &': {
      boxShadow: 'unset',
      margin: baseTheme.spacing(4),
    },

    'header & div.header-tabs': {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 0%)',
    },
  },
};

export const MuiPaper = {
  styleOverrides,
};
