import MaterialTable, { Column } from '@material-table/core';
import { CircularProgress, Stack } from '@mui/material';
import { Event, useEventsByDateAndSessionId } from 'hive-analytics-react';
import { Fragment, PropsWithRef, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EventData } from './EventData';
import { FilterContext } from './FilterContext';
import { filterEvents, formatDateAndTime } from './utilities';

export interface EventsProps {
  sessionId: string;
}

const renderTime = (e: Event) => formatDateAndTime(e.created, true);

const renderElapsed = (e: Event) => e.properties.elapsed || '';

const renderInfo = (e: Event) => (
  <Fragment>
    <EventData event={e} />
  </Fragment>
);

export function Events({ sessionId }: PropsWithRef<EventsProps>): JSX.Element {
  const { t } = useTranslation();
  const filter = useContext(FilterContext);
  const { startTime, endTime } = filter;
  const rawEvents = useEventsByDateAndSessionId(startTime, endTime, sessionId, {
    debounce: true,
    debounceWait: 5000,
    debounceMaxWait: 30000,
  });
  const events = useMemo(
    () => filterEvents(rawEvents || [], filter),
    [rawEvents, filter]
  );

  const columns = useMemo<Column<Event>[]>(
    () => [
      {
        width: '20%',
        title: t('content.metrics.events.columns.time'),
        field: 'created',
        searchable: false,
        render: renderTime,
      },
      {
        width: '15%',
        title: t('content.metrics.events.columns.event'),
        field: 'properties.eventId',
        searchable: false,
      },
      {
        width: '15%',
        title: t('content.metrics.events.columns.elapsed'),
        field: 'properties.elapsed',
        searchable: false,
        render: renderElapsed,
      },
      {
        width: '50%',
        title: t('content.metrics.events.columns.info'),
        field: 'properties.data',
        render: renderInfo,
      },
    ],
    [t]
  );
  return (
    <Stack className="metrics-events">
      {!rawEvents && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {rawEvents && (
        <MaterialTable
          columns={columns}
          data={events}
          components={{
            Toolbar: () => null,
          }}
          options={{
            paging: false,
          }}
        />
      )}
    </Stack>
  );
}
