import { Stack, Typography } from '@mui/material';
import {
  formatPhoneNumber,
  Patient,
  PatientService,
  spacing,
} from 'ecarepd-shared-utilities';
import { PropsWithRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_PATIENT } from '../constants';
import { PatientInfoItem } from './PatientInfoItem';

export interface PatientInfoProps {
  patient?: Patient;
  width?: number | string;
  flexGrow?: number | string;
  linkToPatient?: boolean;
}
export function PatientInfo({
  patient,
  width,
  flexGrow,
  linkToPatient,
}: PropsWithRef<PatientInfoProps>): JSX.Element {
  const { t } = useTranslation();
  const notAvailable = t('content.patient.info.n_a');

  const patientLink = useMemo(() => {
    if (!patient || !linkToPatient) {
      return '';
    }

    return PATH_PATIENT.replace(':patientId', patient.id);
  }, [patient, linkToPatient]);

  return (
    <Stack
      width={width}
      flexGrow={flexGrow}
      className="patient-info"
      spacing={1}
    >
      <Typography variant="h5">{t('content.patient.info.title')}</Typography>

      <Stack
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        // We CANNOT easily theme MuiStack, so this sx is permitted here
        sx={{ gridGap: `${spacing * 2}px` }}
      >
        <PatientInfoItem
          label={t('content.patient.info.labels.name')}
          value={patient?.properties?.name || notAvailable}
          navLink={patientLink}
        />
        <PatientInfoItem
          label={t('content.patient.info.labels.diagnostic_date')}
          value={
            PatientService.formatDate(patient?.properties?.diagnosticDate) ||
            notAvailable
          }
        />
        <PatientInfoItem
          label={t('content.patient.info.labels.email')}
          value={patient?.properties?.email || notAvailable}
        />
        <PatientInfoItem
          label={t('content.patient.info.labels.dob')}
          value={
            PatientService.formatDate(patient?.properties?.dateOfBirth) ||
            notAvailable
          }
        />
        <PatientInfoItem
          label={t('content.patient.info.labels.phone')}
          value={patient?.properties?.phone || notAvailable}
          formatter={formatPhoneNumber}
          link={
            patient?.properties?.phone
              ? `tel:${patient?.properties?.phone}`
              : undefined
          }
        />
        <PatientInfoItem
          label={t('content.patient.info.labels.gender')}
          value={patient?.properties?.gender || notAvailable}
        />
      </Stack>
    </Stack>
  );
}
