import { PropsWithRef, useCallback, useMemo, useRef, useState } from 'react';
import { SelectChangeEvent, Stack } from '@mui/material';
import {
  ChartBody,
  ChartHeader,
  Patient,
  useStatsByPatientId,
} from 'ecarepd-shared-utilities';
import moment from 'moment';
import _ from 'lodash';

const STORAGE_CHART_PERIOD = 'call-request-chart-period';

export interface SymptomHistoryProps {
  patient?: Patient;
  priorityId?: string;
}

export function SymptomHistory({
  patient,
  priorityId,
}: PropsWithRef<SymptomHistoryProps>): JSX.Element | null {
  const [period, setPeriod] = useState<number>(() =>
    parseInt(localStorage.getItem(STORAGE_CHART_PERIOD) || '30')
  );

  const changePeriod = useCallback((event: SelectChangeEvent<number>) => {
    const newPeriod = event.target.value as number;
    setPeriod(newPeriod);
    localStorage.setItem(STORAGE_CHART_PERIOD, newPeriod.toString());
  }, []);

  const startDate = useMemo(
    () =>
      moment().subtract(period, 'day').startOf('day').utc(true).toISOString(),
    [period]
  );

  const [stats] = useStatsByPatientId(
    patient?.properties.patientID || '',
    startDate,
    moment().endOf('day').utc(true).toISOString()
  );

  const priorityIds = useRef<string[]>(_.compact(_.castArray(priorityId)));
  const visiblePriorities = useRef(new Set(priorityIds.current));
  const visibleWearableDatas = useRef(new Set());

  if (!patient) {
    return null;
  }

  return (
    <Stack height="300px" spacing={2} className="patient-chart">
      <ChartHeader
        titleId={'content.call_request.chart.title'}
        period={period}
        changePeriod={changePeriod}
      />

      <ChartBody
        stats={stats}
        priorityIds={priorityIds.current}
        visiblePriorities={visiblePriorities.current}
        visibleWearableDatas={visibleWearableDatas.current}
        patient={patient}
        titleId={'content.call_request.chart.title'}
        hideFooter={true}
      />
    </Stack>
  );
}
