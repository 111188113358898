import { Typography, Stack } from '@mui/material';
import MaterialTable, { Column } from '@material-table/core';
import { SurveyResults, useSurveyResults } from 'ecarepd-shared-utilities';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

function formatDate(date?: string | moment.Moment): string {
  if (!date) {
    return '';
  }

  return moment(date).format('YYYY-MM-D h:mma');
}

export default function Surveys() {
  const { t } = useTranslation();

  const surveys = useSurveyResults();

  const renderDateSurvey = useCallback((s: SurveyResults) => {
    return <Typography variant="body2">{formatDate(s.created)}</Typography>;
  }, []);

  const renderSurveyResponse = useCallback((s: SurveyResults) => {
    const responses = Object.keys(s.properties.responses).map((key) => {
      if (
        typeof s.properties.responses[key] === 'string' ||
        typeof s.properties.responses[key] === 'number'
      ) {
        return (
          <Typography width="20%">
            {key}: {s.properties.responses[key]}
          </Typography>
        );
      } else {
        return Object.keys(s.properties.responses[key]).map((subKey) => (
          <Typography width="20%">
            {subKey}: {s.properties.responses[key][subKey]}
          </Typography>
        ));
      }
    });
    return (
      <Stack display="flex" flexDirection="row" flexWrap="wrap">
        {responses}
      </Stack>
    );
  }, []);

  const title = t('content.survey_table.title', { count: _.size(surveys) });

  const [columns] = useState<Array<Column<SurveyResults>>>(() => {
    return [
      {
        title: t('content.survey_table.survey_version'),
        width: '5%',
        field: 'properties.version',
      },
      {
        title: t('content.survey_table.survey_response'),
        width: '75%',
        field: 'properties.responses',
        render: renderSurveyResponse,
      },
      {
        title: t('content.survey_table.survey_date'),
        width: '20%',
        field: 'created',
        defaultSort: 'desc',
        render: renderDateSurvey,
      },
    ];
  });

  return (
    <div className="surveys-table">
      <MaterialTable
        title={title}
        columns={columns}
        data={surveys || []}
        options={{ pageSize: 10 }}
      />
    </div>
  );
}
