import { Button, Stack, Typography } from '@mui/material';
import {
  MessageBarContext,
  MessageBarContextProps,
  Patient,
  PatientService,
} from 'ecarepd-shared-utilities';
import { useCurrentLanguage } from 'hive-react-utils/src/i18n';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSVDownload } from 'react-csv';

interface ExportPatientDataProps {
  patient?: Patient;
  width?: number | string;
  flexGrow?: number | string;
}

export function ExportPatientData({
  patient,
  width,
  flexGrow,
}: ExportPatientDataProps) {
  const { t } = useTranslation();
  const language = useCurrentLanguage();
  const { openMessageBar, closeMessageBar } =
    useContext<MessageBarContextProps>(MessageBarContext);
  const [csvData, setCsvData] = useState<string[][] | null>(null);

  const handleClick = useCallback(async () => {
    if (csvData) {
      setCsvData(null);
    }
    if (patient?.properties.patientID) {
      await PatientService.exportPatientData(
        patient.properties.patientID,
        language
      )
        .then(setCsvData)
        .catch((error) => {
          console.error('error: ', error);
          openMessageBar(
            true,
            t('content.patient.export_data.error'),
            {
              onClose: () => {
                closeMessageBar();
              },
            },
            {
              severity: 'error',
            }
          );
        });
    }
  }, [
    closeMessageBar,
    csvData,
    language,
    openMessageBar,
    patient?.properties.patientID,
    t,
  ]);

  return (
    <Stack width={width} flexGrow={flexGrow} className="wearable-status">
      <Typography variant="h5">
        {t('content.patient.export_data.title')}
      </Typography>
      <Button onClick={handleClick}>
        {t('content.patient.export_data.export')}
      </Button>
      {csvData != null ? <CSVDownload data={csvData} target="_self" /> : null}
    </Stack>
  );
}
