import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { AppNames, LoginService } from 'ecarepd-shared-utilities';
import './index.css';
import App from './App';

// The following just need to be imported to start things
import './services/LocalizationService';

// Use different keys for the clinician in order to share the browser
// for development
const KEY_TOKEN = 'hive-clinician-token';
const KEY_TOKEN_EXPIRATION = 'hive-clinician-token-expiration';
const KEY_USERNAME = 'hive-clinician-username';

LoginService.init({
  appName: AppNames.Clinician,
  keyToken: KEY_TOKEN,
  keyTokenExpiration: KEY_TOKEN_EXPIRATION,
  keyUsername: KEY_USERNAME,
}).then(() => {
  const container = document.getElementById('root') as Element;
  const root = ReactDOMClient.createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});
