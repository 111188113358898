import { ComponentsOverrides } from '@mui/material';
import { COLOURS, spacing } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiFormControl'] = {
  root: {
    '.metrics .metrics-filters-date-range &': {
      '.MuiOutlinedInput-input': {
        padding: `${spacing * 2}px`,
        paddingRight: 0,
        fontStyle: 'italic',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: COLOURS.DarkBlue,
      },
      '.MuiInputAdornment-root': {
        marginLeft: 0,
        '.MuiButtonBase-root': {
          padding: `${spacing}px`,
        },
        '.MuiSvgIcon-root': {
          width: '16px',
          height: '16px',
        },
      },
    },
  },
};

export const MuiFormControl = {
  styleOverrides,
};

const labelStyleOverrides: ComponentsOverrides['MuiFormControlLabel'] = {
  root: {
    '.metrics &': {},
  },
  label: {
    '.metrics &': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      color: COLOURS.DarkBlue,
    },
  },
};

export const MuiFormControlLabel = {
  styleOverrides: labelStyleOverrides,
};
