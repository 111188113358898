import { ComponentsOverrides, Theme } from '@mui/material';
import { COLOURS } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides<Theme>['MuiTypography'] = {
  h5: {
    '.metrics &': {
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      color: COLOURS.Primary,
    },
  },
  h6: {
    '.metrics &': {
      fontStyle: 'italic',
      fontWeight: 900,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.4px',
      color: COLOURS.DarkBlue,
    },
  },
  body1: {
    '.metrics &': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '-0.175px',
      color: COLOURS.DarkBlue,
    },
  },
  body2: {
    '.metrics &': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '-0.175px',
      color: COLOURS.Black,
    },
  },
};

export const MuiTypography = {
  styleOverrides,
};
