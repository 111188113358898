import { createTheme, ThemeOptions } from '@mui/material';
import { theme as baseTheme } from 'ecarepd-shared-utilities';
import { MuiDialogTitle } from './dialog';
import { MuiPaper } from './paper';
import { MuiTab, MuiTabs } from './tab';
import { MuiTable, MuiTableCell, MuiTableHead, MuiTableRow } from './table';
import { MuiToolbar } from './toolbar';
import { MuiTypography } from './typography';
import { metricsTheme } from './metrics';
import { MuiInputBase } from './input';

const updates: ThemeOptions = {
  components: {
    MuiDialogTitle,
    MuiInputBase,
    MuiPaper,
    MuiTab,
    MuiTabs,
    MuiTable,
    MuiTableCell,
    MuiTableHead,
    MuiTableRow,
    MuiToolbar,
    MuiTypography,
  },
};

export const clinicianTheme = createTheme(baseTheme, updates, metricsTheme);
