import { useContext, useMemo } from 'react';
import { CallRequest } from 'ecarepd-shared-utilities';
import _ from 'lodash';
import { DataContext } from '../contexts/DataContext';

export function useCallRequestById(
  callRequestId?: string
): CallRequest | undefined {
  const { callRequests } = useContext(DataContext);

  return useMemo(
    () => _.find(callRequests, { id: callRequestId || '' }),
    [callRequestId, callRequests]
  );
}
