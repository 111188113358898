import { ComponentsOverrides } from '@mui/material';
import { COLOURS, spacing } from 'ecarepd-shared-utilities';

const rowStyleOverrides: ComponentsOverrides['MuiTableRow'] = {
  root: {
    '.metrics &': {
      '&:nth-of-type(even)': {
        backgroundColor: COLOURS.OddRowBackground,
        boxSizing: 'border-box',
      },
    },
  },
};

export const MuiTableRow = {
  styleOverrides: rowStyleOverrides,
};

const cellStyleOverrides: ComponentsOverrides['MuiTableCell'] = {
  body: {
    '.metrics &': {
      // Note that the MaterialTable uses inline styles, hence the !important
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      fontSize: '16px !important',
      lineHeight: '24px !important',
      color: `${COLOURS.DarkerGrey} !important`,
      paddingTop: `${spacing}px`,
      paddingBottom: `${spacing}px`,
    },
  },
  head: {
    '.metrics &': {
      backgroundColor: COLOURS.LightBlueBackground,
      paddingTop: `${spacing}px`,
      paddingBottom: `${spacing / 4}px`,
      '.MuiButtonBase-root': {
        fontStyle: 'italic',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '24px',
        color: COLOURS.DarkerGrey,
      },
    },
  },
};

export const MuiTableCell = {
  styleOverrides: cellStyleOverrides,
};
