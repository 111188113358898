import { ComponentsOverrides } from '@mui/material';
import { COLOURS } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiToolbar'] = {
  root: {
    '.patients-table &, .call-requests-table &': {
      h6: {
        fontStyle: 'italic',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
        color: COLOURS.Primary,
      },
    },
  },
};

export const MuiToolbar = {
  styleOverrides,
};
