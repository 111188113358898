import { Button, Stack, TextField, Typography } from '@mui/material';
import {
  CallRequest,
  PatientService,
  useApiCallWithMessageBar,
} from 'ecarepd-shared-utilities';
import { AnalyticsService } from 'hive-analytics-react';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { PropsWithRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientInfoItem } from '../patient/PatientInfoItem';

export interface RequestSummaryProps {
  callRequest?: CallRequest;
  width?: number | string;
  flexGrow?: number | string;
}

function formatRequestDate(date?: string | moment.Moment): string {
  if (!date) {
    return '';
  }

  return moment(date).format('LL');
}

export function RequestSummary({
  callRequest,
  width,
  flexGrow,
}: PropsWithRef<RequestSummaryProps>): JSX.Element {
  const { t } = useTranslation();
  const notAvailable = t('content.patient.info.n_a');

  const confirmFn = useConfirm();
  const { progress: completingCall, callback: onCompleteCallCallback } =
    useApiCallWithMessageBar({
      canExecute: () => !!callRequest?.id,
      execute: () => PatientService.completeCallRequest(callRequest!.id),
      confirmation: {
        enabled: true,
        title: t('content.call_request.complete.confirmation_title'),
        description: t('content.call_request.complete.confirmation_message'),
        confirmFn,
      },
    });

  const onCompleteCall = useCallback(() => {
    AnalyticsService.logClick('CALL_REQUEST_COMPLETED', {
      callRequestId: callRequest?.id,
    });
    onCompleteCallCallback();
  }, [callRequest?.id, onCompleteCallCallback]);

  return (
    <Stack
      width={width}
      flexGrow={flexGrow}
      className="patient-info request-summary"
    >
      {/* This is there just to align the content with the patient info panel */}
      <Typography variant="h5" visibility="hidden">
        &nbsp;
      </Typography>

      <Stack direction="row" spacing={10} alignItems="start">
        <Stack
          flexGrow="1"
          spacing={2}
          display="grid"
          gridTemplateColumns="repeat(1, 1fr)"
        >
          <PatientInfoItem
            label={t('content.call_request.info.labels.date')}
            labelWidth={30}
            value={
              formatRequestDate(callRequest?.properties.date) || notAvailable
            }
          />

          <PatientInfoItem
            label={t('content.call_request.info.labels.priority')}
            labelWidth={30}
            value={callRequest?.properties.priority?.name || notAvailable}
          />

          {callRequest?.properties.completed ? (
            <PatientInfoItem
              disabled={callRequest?.properties.completed === ''}
              label={t('content.call_request.info.labels.completed')}
              labelWidth={30}
              value={formatRequestDate(callRequest?.properties.completed)}
            />
          ) : (
            <Button
              variant="outlined"
              disabled={
                completingCall || callRequest?.properties?.completed !== ''
              }
              onClick={onCompleteCall}
            >
              {t('content.call_request.complete.button')}
            </Button>
          )}
        </Stack>

        <Stack flexGrow="2" height="100%" spacing={1}>
          <Typography variant="h6">
            {t('content.call_request.info.labels.note')}
          </Typography>

          <TextField
            multiline
            value={callRequest?.properties.comment}
            disabled={true}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
